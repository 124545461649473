<template>
    <div class="home">
        <div class="is-pulled-right"><button class="button is-info is-small" @click="backToOverview()">Back to Overview </button></div><br /><br />

        <div class="notification is-warning " v-if="loading==true">
            <p>Loading references ...</p>
        </div>

        
        <div class="box" v-if="loading==false">	
                <div v-if="add_new_reference==true">
                    <div class="is-pulled-right"><button class="button is-small is-danger" @click="add_new_reference=false" :disabled="working==true">Cancel</button></div>
                    <h4 class="title is-4">Add New Reference Details</h4>
                    <form  @submit.prevent="saveReference()">
                        
                        
                        <div class="field">
                            <label class="label">Forename</label>
                            <div class="control">
                                <input class="input" type="text" v-model="forename" required>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Surname</label>
                            <div class="control">
                                <input class="input" type="text" v-model="surname" required>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Position</label>
                            <div class="control">
                                <input class="input" type="text" v-model="position" required>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Email</label>
                            <div class="control">
                                <input class="input" type="email" v-model="email" required>
                            </div>
                        </div>
                       
                        <div class="control">
                        <button class="button is-success" type="submit" :disabled="working==true">Save This Reference</button>
                        </div>
                    </form>

                </div>
                <div v-else>
                    <div class="is-pulled-right"> 
                    <button class="button is-success " @click="add_new_reference = true">Add New Reference Details</button>
                    </div>
                    
                    <h4 class="title is-4">References</h4>
                    <div v-if="references.length>0">
                            <table class="table is-fullwidth is-striped" >
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Position</th>
                                        <th>Email</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="row in references" :key="row.id">
                                        <td>{{ row.title}} {{ row.forename}} {{ row.surname}}</td>
                                        <td>{{ row.position }}</td>
                                        <td>{{ row.email }}</td>
                                        <td><button type="button" class="button is-small is-danger" @click="deleteReference(row.id)" :disabled="working==true">Delete</button></td>
                                    </tr>
                                    </tbody>
                            </table>

                        
                    </div>
                    <div v-else>
                        <div class="notification is-warning " >
                            <p>You have not entered any references yet.</p>
                        </div>
                    </div>
                </div>

          </div>


        
          
      </div>
  </template>

<script>
    import UtilityService from '@/services/UtilityService.js';
    import MemberApplicationService from '@/services/MemberApplicationService.js';
    
    export default {
      name: 'ViewApplicationWorkExperience',
        mixins: [],
        props: ['application'],
        data: function () {
            return {
                   application_code : this.$route.params.id,
                   loading:true,
                   accessToken: '',
                   references: [],
                   add_new_reference : false,
                   
                  title: '',
                  forename: '',
                  surname: '',
                  position: '',
                  email: '',
                   
                   working: false
            }
        },
        created() {
          this.getReferences()
        },
        methods: {
            backToOverview() {
                if(confirm("Please make sure you have filled in details of 2 references")) { 
                    this.$emit('backToMenu')
                }
                else {
                    return false
                }
            },  
            async getReferences() {
                this.loading = true;
                UtilityService.getSimpleApiData(this.accessToken, "web/applications/" + this.application_code + '/references').then(
                    response => {
                        this.references = response 
                        this.loading = false;
                    }
                );
            },
           
            async saveReference() {
                
                this.working = true
                var formdata = { 
                    title : this.title,
                    forename : this.forename,
                    surname : this.surname,
                    position : this.position,
                    email : this.email
                    
                }; 

                MemberApplicationService.saveReference(this.accessToken, this.application_code, formdata)
                .then((response) => {
                    console.log(response)
                    //this.$emit('backToMenu')
                    this.getReferences();
                    this.add_new_reference = false

                    this.title = ''
                    this.forename = ''
                    this.surname = ''
                    this.position = ''
                    this.email = ''

                    this.working = false
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("Something went wrong. Make sure you have filled in all the fields. ")
                        }
                        if(error.response.status === 406)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("The barcodes passed in were not valid. Please double check and try again.")
                        }
                        
                        this.working = false
                });     
            },
            async deleteReference(reference_id) {
                if(confirm('Are you sure you want to delete this entry ?'))
                {
                    this.working = true
                    var formdata = { 
                        active : 0,
                    }; 

                    MemberApplicationService.deleteReference(this.accessToken, this.application_code, reference_id, formdata)
                    .then((response) => {
                        console.log(response)
                        //this.$emit('backToMenu')
                        this.getReferences();
                        this.add_new_reference = false

                        
                        this.working = false
                        
                        }).catch(() => {
                            alert("Something went wrong. Please try again or drop us an email")
                            
                            this.working = false
                    }); 
                }    
            },
            
            
           
        }
    }
    </script>